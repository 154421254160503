.white-text {
  color: white;
}

.icon {
  font-size: 24px;
  color: white;
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

.banner {
  background-color: #2d3038;
}
