/* lg */
@media (min-width: 1400px) {
}

/* md */
@media (min-width: 992px) and (max-width: 1199px) {
  .banner-info h2 {
    font-size: 110px;
    line-height: 80px;
  }
  #navigation.menu-one .navbar {
    width: 27%;
  }
  .achievement li + li {
    margin-left: 20px;
  }
  .skill-section .rating-bar {
    width: 47%;
  }
  .progress-content,
  .expertise .sub-content {
    padding-right: 20px;
  }
  .language-skill li + li {
    margin-left: 45px;
  }
  #navigation.menu-two .navbar-nav > li a {
    padding: 22px 10px;
    font-size: 12px;
  }
  #navigation.menu-two .navbar-nav > li.current a,
  #navigation.menu-two .navbar-nav > li a:hover {
    padding-bottom: 30px;
  }
  .home-two .banner-info h1 {
    font-size: 60px;
  }
  #navigation .navbar:before,
  #navigation .navbar:after {
    height: 64px;
  }
  .logo {
    height: 64px;
    padding: 2px 15px;
  }
}

@media (max-width: 991px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
    margin-top: 17px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
  }
  .navbar-nav > li {
    float: none;
  }
  .collapse.in {
    display: block !important;
  }
  .home-two .home-social {
    top: 100px;
  }
  .banner-content .banner-info {
    top: 100%;
    margin-top: -220px;
  }
  #navigation .navbar:before {
    width: 200%;
  }
}

/* sm */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-toggle {
    margin-top: 15px;
  }
  #navigation.menu-one .navbar-nav > li:first-child a {
    margin-left: 20px;
  }
  #navigation.menu-one .navbar li img {
    width: 80%;
    height: inherit;
  }
  .banner-info h1 {
    font-size: 40px;
  }
  .section-title h1 {
    font-size: 50px;
  }
  .portfolio-info h3 {
    font-size: 22px;
  }
  .profile-info h1 {
    font-size: 46px;
  }
  .sub-content {
    padding-left: 0;
  }
  .banner-info h2 {
    font-size: 85px;
    line-height: 50px;
  }
  .banner-info h3 {
    letter-spacing: 15px;
  }
  .achievement li {
    width: 48%;
  }
  .achievement li + li {
    margin-left: 0;
  }
  .achievement-info h4 {
    font-size: 14px;
  }
  .skill-section .rating-bar {
    width: 46%;
  }
  .progress-content {
    padding-right: 20px;
  }
  .language-skill .percent {
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 28px;
  }
  .language-skill li + li {
    margin-left: 22px;
  }
  .post-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .language-proficiency ul > li + li {
    margin-left: 5px;
    padding-left: 17px;
  }
  .personal-info .address li h5,
  .portfolio li h5 {
    min-width: 130px;
  }
  .exprience-image {
    height: 230px;
  }
  .section-padding {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .profile-section .section-padding {
    padding: 50px 0;
  }
  .banner-info {
    bottom: 60px;
  }
  .entry-summary {
    padding: 30px;
  }
  .post-action li {
    max-width: 50%;
  }
  .post-action {
    padding: 30px 20px 25px;
  }
  #navigation.menu-one .navbar-nav {
    padding-bottom: 0;
  }
  #navigation.menu-one .navbar-nav > li ul li a {
    padding-left: 5px;
    padding-right: 5px;
  }
  #navigation.menu-one .navbar-nav > li span {
    left: 60px;
  }
  #navigation.menu-one .navbar-nav > li i {
    font-size: 25px;
    left: -40px;
  }
  #navigation.menu-one .navbar-nav > li ul.social i {
    font-size: 14px;
  }
  #navigation.menu-one .navbar-nav > li {
    height: 6.5%;
  }
  #navigation.menu-one .navbar-nav > li .social {
    left: 20px;
  }
  #navigation.menu-two .navbar-nav > li a {
    padding: 15px 20px;
  }
  #navigation.menu-two .navbar-nav > li.current a,
  #navigation.menu-two .navbar-nav > li a:hover {
    padding-bottom: 15px;
  }
  .home-two .banner-info h1 {
    font-size: 50px;
  }
  #navigation .navbar:before {
    width: 200%;
  }
  #navigation.menu-one .navbar {
    width: 100%;
  }
  #navigation.menu-one .navbar:before {
    left: -100%;
    right: 0;
  }
  .home-one .navbar-nav {
    margin-top: 10px;
  }
}

/* xs */
@media (max-width: 767px) {
  #navigation .navbar:before {
    width: 200%;
  }
  .navbar-toggle {
    margin-top: 8px;
  }
  #home-banner {
    background-color: #f9f9f9;
    display: none;
  }
  .banner-info {
    margin-top: 100px;
    bottom: 30px;
  }
  .banner-info h1 {
    font-size: 30px;
  }
  .banner-info h2 {
    font-size: 50px;
    line-height: 25px;
  }
  .banner-info h3 {
    font-size: 14px;
    letter-spacing: 15px;
  }
  .section-padding {
    overflow: hidden;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .about-section .section-title,
  .section-title {
    margin-bottom: 20px;
  }
  .section-title h1 {
    font-size: 36px;
  }
  .about-info .signature h1 {
    margin-bottom: 10px;
  }
  .about-section address {
    line-height: 24px;
  }
  .achievement li + li {
    margin-left: 0;
  }
  .achievement li {
    float: none;
    display: inline-block;
    margin-bottom: 20px;
    width: 49%;
  }
  .achievement-info h4 {
    font-size: 14px;
  }
  .achievement-info .counter {
    font-size: 40px;
    line-height: 42px;
  }
  .skill-section .rating-bar {
    width: 97%;
  }
  .bar-right {
    float: none;
  }
  .language-skill .percent {
    font-size: 24px;
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
  .language-skill li + li {
    margin-left: 40px;
  }
  .language-skill {
    margin-top: 0;
  }
  .exprience-info h3 {
    font-size: 20px;
  }
  .exprience-image {
    min-height: 200px;
  }
  .exprience-info h5 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .education-info h3 {
    margin-top: 10px;
    font-size: 20px;
  }
  .portfolio-item {
    margin-bottom: 15px;
  }
  .portfolio-item img {
    width: 100%;
  }
  .education-section hr,
  .contact-section hr {
    margin: 0;
  }
  .text-info {
    margin-bottom: 20px;
  }
  .contact-section h3 {
    font-size: 20px;
  }
  .contact-section .icons {
    height: 65px;
  }
  .contact-section li {
    overflow: hidden;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .blog-section .entry-post {
    margin-bottom: 15px;
  }
  .blog-section .entry-post img {
    width: 100%;
  }
  .profile-info h1 {
    font-size: 40px;
  }
  #profile hr,
  .exprience-section hr {
    margin: 0;
  }
  #profile h3 {
    margin-bottom: 15px;
    font-size: 20px;
  }
  .profile-logo {
    margin-right: 20px;
  }
  .profile-section .section-padding {
    padding-bottom: 30px;
    padding-top: 40px;
  }
  .profile-section .profile-info {
    margin-bottom: 0;
  }
  .sub-content {
    padding-left: 45px;
  }
  .profile-section .icons {
    margin-right: 8px;
  }
  .educational-background .icons {
    margin-right: 0;
  }
  .work-history .history h6 {
    margin-bottom: 10px;
  }
  #profile .educational-background h3,
  #profile .work-history h3 {
    margin-bottom: 5px;
  }
  .personal-info .address li h5,
  .portfolio li h5 {
    min-width: 120px;
  }
  .personal-info .address li span,
  .portfolio li span {
    margin-right: 10px;
  }
  .language-proficiency ul > li {
    width: 49%;
    margin-bottom: 20px;
  }
  .language-proficiency ul > li ul li {
    width: inherit;
    margin-bottom: 0;
  }
  .language-proficiency ul > li + li {
    margin-left: 0;
    border: 0;
    padding-left: 0;
  }
  .btn.btn-primary {
    font-size: 14px;
    padding: 7px 15px;
  }
  .profile-section .btn.btn-primary {
    margin-top: 15px;
  }
  .declaration h1 {
    margin-top: 20px;
  }
  .expertise .skill-progress,
  .skill-progress,
  .progress {
    margin-bottom: 20px;
  }
  .home-one #navigation .navbar,
  .home-two #navigation .navbar {
    width: 100%;
  }
  .navbar-collapse {
    border-top: 0;
  }
  #blog-details .post-content h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .entry-summary {
    padding: 20px;
  }
  .entry-summary p {
    margin-bottom: 10px;
  }
  .post-action li {
    max-width: 50%;
  }
  .post-action {
    padding: 30px 20px 25px;
  }
  .blog-social {
    margin: 20px 0;
  }
  #navigation.menu-one .navbar-nav > li i {
    display: none;
  }
  #navigation.menu-one .navbar-nav > li ul li a:hover i,
  #navigation.menu-one .navbar-nav > li ul li i {
    display: block;
    font-size: 14px;
  }
  #navigation.menu-one .navbar-nav > li {
    height: 15%;
  }
  #navigation.menu-one .navbar-nav > li {
    margin-left: 0;
    padding-left: 10px;
  }
  #navigation.menu-one .navbar-nav > li:last-child,
  #navigation.menu-one .navbar-nav > li:last-child:hover {
    margin-left: 0;
    padding-left: 0;
  }
  #navigation.menu-one .navbar-nav > li ul li a {
    margin-top: 18%;
  }
  #navigation.menu-one .navbar-nav > li span {
    left: 20px;
  }
  #navigation.menu-one .navbar-nav > li.current span,
  #navigation.menu-one .navbar-nav > li:hover span {
    left: 25px;
  }
  #navigation .navbar-nav {
    margin-top: 0;
  }
  #navigation .navbar-nav > li .social {
    left: 25px;
  }
  .menu-one .container {
    padding: 0;
  }
  .home-one .logo {
    padding-left: 15px;
  }
  #navigation.menu-two .navbar-nav > li a {
    padding: 10px 15px;
    font-size: 13px;
  }
  #navigation.menu-two .navbar-nav > li.current a,
  #navigation.menu-two .navbar-nav > li a:hover,
  #navigation.menu-two .navbar-nav > li:last-child a:hover,
  #navigation.menu-two .navbar-nav > li.current:last-child a {
    padding-bottom: 10px;
  }
  .home-two .banner-info {
    text-align: left;
    width: 100%;
    padding-left: 25px;
  }
  .logo {
    background-color: #2d3038;
    height: 57px;
    padding: 8px 15px;
    max-width: 60%;
  }
  .home-one .logo {
    max-width: 100%;
    margin-left: 0;
  }
  .logo img {
    max-width: 75%;
  }
  .home-two .home-social {
    top: 100%;
    margin-top: -40px;
    margin-left: 20px;
  }
  .home-two .banner-info h1 {
    font-size: 40px;
    margin-bottom: 5px;
  }
  #navigation .navbar:before,
  #navigation .navbar:after {
    height: 57px;
  }
  .banner-content .banner-info {
    top: 100%;
    margin-top: -180px;
  }
  .home-banner-one .banner-content {
    padding: 15px;
  }
}

/* XS Portrait */
@media (max-width: 479px) {
  .banner-info h2 {
    font-size: 48px;
    line-height: 15px;
  }
  .banner-info h3 {
    letter-spacing: 8px;
  }
  .achievement {
    text-align: left;
  }
  .section-title h1,
  .home-two .section-title h1 {
    font-size: 30px;
  }
  .skill-section ul {
    text-align: center;
  }
  .language-skill li + li {
    margin-left: 0;
  }
  .language-skill li {
    float: none;
    display: inline-block;
    width: 49%;
  }
  .exprience-image {
    float: none;
    height: inherit;
    min-height: inherit;
    margin-top: 10px;
  }
  .exprience-info {
    margin-bottom: 20px;
  }
  #profile h3 {
    font-size: 19px;
  }
  .sub-content {
    padding-left: 0;
  }
  .personal-info .address li h5,
  .portfolio li h5 {
    min-width: inherit;
  }
  .personal-info .address li span,
  .portfolio li span {
    margin-right: 5px;
    padding-left: 10px;
  }
  .personal-info .address li,
  .portfolio li {
    font-size: 12px;
  }
  .profile-logo {
    float: none;
    margin-right: 0;
    margin-bottom: 20px;
  }
  #blog-details .post-content {
    margin-left: 0;
  }
  #blog-details .entry-post .time {
    float: none;
    margin-bottom: 10px;
  }
  #blog-details .post-content h2 {
    font-size: 24px;
    line-height: 26px;
  }
  .post-action li {
    max-width: 100%;
    float: none;
  }
  .post-action .next-post {
    text-align: left;
    margin-top: 15px;
  }
  .blog-social li {
    width: 24%;
  }
  #navigation .navbar-nav > li {
    height: 8%;
  }
  .menu-two .logo {
    padding-bottom: 5px;
  }
  .logo img {
    max-width: 90%;
  }
  .home-two .banner-info {
    padding-left: 15px;
  }
  .home-two .home-social {
    margin-left: 15px;
  }
  .home-one .logo {
    max-width: 80%;
    height: 57px;
  }
  #navigation.menu-one .navbar-nav > li {
    height: 12%;
  }
}
